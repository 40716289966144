@tailwind base;
h3 {
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 1.75rem;
}

@tailwind components;
@tailwind utilities;

/* Chrome, Safari, Edge, Opera */
input[type='number'].number-remove-arrows::-webkit-outer-spin-button,
input[type='number'].number-remove-arrows::-webkit-inner-spin-button,
input[type='number'].number-remove-arrows {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}
.overflow-y-overlay {
  overflow-y: overlay;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: overlay;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: rgb(155, 155, 155);
}

select::-webkit-scrollbar {
  width: 15px;
  background-color: #f3f4f6;
}

select::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: rgb(155, 155, 155);
}

div::-webkit-scrollbar {
  width: 15px;
}

.bms-table::-webkit-scrollbar {
  width: 15px;
  background-color: rgba(216, 220, 227, 0.1);
}

div::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 5px solid transparent;
  background-clip: content-box;
  background-color: rgb(155, 155, 155);
}

/* @media all {
  .page-break {
    display: none;
  } */
/* } */

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 5rem;
    display: block;
    page-break-before: always;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.coverage-template-custom {
  width: 28%;
}

.layout {
  width: 100%;
  padding-top: 2.5rem;
}
.navb {
  width: 100%;
}

@media (min-width: 640px) {
  .coverage-template-custom {
    width: 14%;
  }
  .layout {
    width: 95%;
    padding-top: 0;
  }
  .navb {
    width: 5%;
  }
}
